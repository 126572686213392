.custom-scrollbar {
  overflow-y: scroll !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px !important;
  background-color: #F1F5F9 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #94A3B8 !important;
  border-radius: 4px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #F1F5F9 !important;
  border-radius: 4px !important;
} 